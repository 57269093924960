import './shared.css'

import $ from 'jquery'

window.$ = $

$(document).ready(function () {
  $('.with-href').on('click', function () {
    const href = $(this).data('href')
    if (href) {
      window.location.href = href
    }
  })
  $('table.clickable-rows > tbody > tr').on('click', function () {
    const url = $(this).data('url')
    if (url) {
      window.location.href = url
    }
  })
})
